@tailwind base;
@tailwind components;
@tailwind utilities;

@import "~slick-carousel/slick/slick.css";
@import "~slick-carousel/slick/slick-theme.css";

@font-face {
  font-family: 'Bukhari';
  src: url('/fonts/Bukhari_Script.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

.body {
  font-family: 'Bukhari', cursive;
}

.video-container {
  position: relative;
  padding-bottom: 56.25%; /* 16:9 Aspect Ratio */
  height: 0;
  overflow: hidden;
  max-width: 100%;
  background: #000;
}

.video-container iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.custom-checkbox {
  @apply appearance-none w-4 h-4 border border-gray-400 outline-none cursor-pointer;
}

.custom-checkbox:checked {
  @apply bg-black;
}

.custom-checkbox:disabled {
  @apply bg-gray-300 border-gray-300 cursor-not-allowed;
}

@layer utilities {
  .paused {
    animation-play-state: paused;
  }
}

@layer base {
  :root {
    --background: 0 0% 100%;
    --foreground: 222.2 47.4% 11.2%;

    --muted: 210 40% 96.1%;
    --muted-foreground: 215.4 16.3% 46.9%;

    --popover: 0 0% 100%;
    --popover-foreground: 222.2 47.4% 11.2%;

    --border: 214.3 31.8% 91.4%;
    --input: 214.3 31.8% 91.4%;

    --card: 0 0% 100%;
    --card-foreground: 222.2 47.4% 11.2%;

    --primary: 222.2 47.4% 11.2%;
    --primary-foreground: 210 40% 98%;

    --secondary: 210 40% 96.1%;
    --secondary-foreground: 222.2 47.4% 11.2%;

    --accent: 210 40% 96.1%;
    --accent-foreground: 222.2 47.4% 11.2%;

    --destructive: 0 100% 50%;
    --destructive-foreground: 210 40% 98%;

    --ring: 215 20.2% 65.1%;

    --radius: 0.5rem;
  }

  .dark {
    --background: 224 71% 4%;
    --foreground: 213 31% 91%;

    --muted: 223 47% 11%;
    --muted-foreground: 215.4 16.3% 56.9%;

    --accent: 216 34% 17%;
    --accent-foreground: 210 40% 98%;

    --popover: 224 71% 4%;
    --popover-foreground: 215 20.2% 65.1%;

    --border: 216 34% 17%;
    --input: 216 34% 17%;

    --card: 224 71% 4%;
    --card-foreground: 213 31% 91%;

    --primary: 210 40% 98%;
    --primary-foreground: 222.2 47.4% 1.2%;

    --secondary: 222.2 47.4% 11.2%;
    --secondary-foreground: 210 40% 98%;

    --destructive: 0 63% 31%;
    --destructive-foreground: 210 40% 98%;

    --ring: 216 34% 17%;

    --radius: 0.5rem;
  }
}

@layer base {
  * {
    @apply border-border;
  }

  body {
    @apply bg-background text-foreground;
    font-feature-settings:
      'rlig' 1,
      'calt' 1;
  }
}

.no-arrows::-webkit-outer-spin-button,
.no-arrows::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.no-arrows {
  -moz-appearance: textfield;
}

.no-scroll::-webkit-scrollbar {
  display: none;
}

.Image-styles_image__gnfW1 {
  object-fit: contain !important;
}

@keyframes scroll {
  0% {
    transform: translateX(0);
  }

  100% {
    transform: translateX(-100%);
  }
}

.animate-scroll {
  white-space: nowrap;
  display: inline-block;
  animation: scroll 5s linear infinite;
}

/* Global super-thin scrollbar styles */
::-webkit-scrollbar {
  width: 6px;  /* Thinner scrollbar width */
  height: 6px; /* Thinner scrollbar height for horizontal scroll */
}

::-webkit-scrollbar-track {
  background-color: #E5E7EB; /* Scrollbar track color */
}

::-webkit-scrollbar-thumb {
  background-color: #c3c4c7; /* Scrollbar thumb color */
  border-radius: 6px; /* Rounded scrollbar thumb */
}

::-webkit-scrollbar-thumb:hover {
  background-color: #1F2937; /* Darken thumb on hover */
}
